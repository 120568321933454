





































































































































































































































































import {v4 as uuid_v4} from 'uuid';
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import {Component, Vue, Watch} from 'vue-property-decorator';
import TicketDisplayType from "@/typescript/enums/TicketDisplayType";
import isNil from "lodash/isNil";
import ITicket from '@/typescript/interfaces/ITicket';
import isEmpty from "lodash/isEmpty";
import ImageResize from 'image-resize';

@Component
export default class extends Vue implements Record<string, unknown> {
    // eslint-disable-next-line no-undef
    [x: string]: unknown;

    private icon64: string = "";
    private icon: File | undefined = new File([], "");
    private desc: string = "";
    private color: string = "";
    private title: string = "";
    private value: string = "";
    private phone: string = "";
    private display: string = "";
    private expireDate: string = "";
    private location: string = "";
    private domain: string = "";
    private email: string = "";

    private iconEnabled: boolean = false;
    private descEnabled: boolean = false;
    private colorEnabled: boolean = false;
    private titleEnabled: boolean = true;
    private valueEnabled: boolean = true;
    private phoneEnabled: boolean = false;
    private displayEnabled: boolean = true;
    private expireDateEnabled: boolean = false;
    private locationEnabled: boolean = false;
    private domainEnabled: boolean = false;
    private emailEnabled: boolean = false;

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get TicketDisplayType(): typeof TicketDisplayType {
        return TicketDisplayType;
    }

    get TicketDisplayTypes(): string[] {
        return Object.keys(TicketDisplayType)
            .filter(value => Number.isNaN(Number.parseInt(value)))
            .map(value => {
                switch (value) {
                    case 'Persistent':
                        return 'Persistente';
                    case 'OneTime':
                        return 'Uso único';
                    case 'Scheduled':
                        return 'Agendado';
                }

                return value;
            });
    }

    get displayValue(): TicketDisplayType {
        return this.TicketDisplayTypes.indexOf(this.display) as TicketDisplayType;
    }

    get IsValid(): boolean {
        const validation = this.validate([
            'title',
            'value',
            'display'
        ])

        return validation === null;
    }

    get utcExpireDate(): string {
        return new Date(`${this.expireDate} 23:59:59`).toUTCString();
    }

    mounted(): void {
        this.icon = undefined;
    }

    upload(): void {
        const payload: ITicket = {
            code: uuid_v4(),
            value: this.valueEnabled ? this.value : '',
            title: this.titleEnabled ? this.title : '',
            type: this.displayEnabled ? this.displayValue : TicketDisplayType.QRCode,
            owner: this.emailEnabled ? this.email : '',
            issuer: '',
            desc: this.descEnabled ? this.desc : '',
            color: this.colorEnabled ? this.color : '',
            expireDate: this.expireDateEnabled ? this.utcExpireDate : undefined,
            location: (this.locationEnabled && this.displayValue === TicketDisplayType.Executor) ? this.location : undefined,
            domain: this.domainEnabled ? this.domain : undefined
        }

        this.dialogManager.dispatch(Dialogs.UploadDialog, payload, this.iconEnabled ? this.icon64 : undefined);
    }

    validate(fields: string[]): string | null {
        for (const field of fields) {
            if (isEmpty(this[field])) {
                return field;
            }
        }
        return null;
    }

    @Watch('icon')
    private async onIconChange(): Promise<void> {
        if (isNil(this.icon))
            return;

        const imageResize = new ImageResize({
            format: 'jpg',
            width: 256,
            outputType: 'base64',
            quality: 0.75
        })

        this.icon64 = await imageResize.play(this.icon as unknown as Blob)
    }

    @Watch('iconEnabled')
    private onIconEnabledChange(): void {
        if (!this.iconEnabled) {
            this.icon64 = '';
        } else {
            this.onIconChange();
        }
    }
}
