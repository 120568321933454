import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "@/plugins/firebase";

Vue.config.productionTip = false

store.dispatch('SETUP_DIALOG_MANAGER').catch(console.error);
store.dispatch('UPDATE_FIREBASE_APP', firebase.app).catch(console.error);
store.dispatch('UPDATE_FIREBASE_AUTH', firebase.auth).catch(console.error);
store.dispatch('UPDATE_FIREBASE_ANALYTICS', firebase.analytics).catch(console.error);
store.dispatch('UPDATE_FIREBASE_PERFORMANCE', firebase.performance).catch(console.error);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
