



































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UploadDialog from "@/components/uploadDialog.vue";
import UpdateDialog from "@/components/updateDialog.vue";
import LoginDialog from "@/components/loginDialog.vue";
import IDynamicTicket from "@/typescript/interfaces/IDynamicTicket";
import LoginState from '@/typescript/enums/LoginState';

@Component({
    components: {UpdateDialog, UploadDialog, LoginDialog},
})
export default class extends Vue {
    search: string = "";
    showValue: boolean = false;
    confirmationDialog: boolean = false;

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get isFetchingIssuedTickets(): boolean {
        return this.$store.getters['GET_FETCHING_ISSUED_TICKETS'];
    }

    get isDeletingTicket(): boolean {
        return this.$store.getters['GET_DELETING_TICKET'];
    }

    get tickets(): IDynamicTicket[] {
        return this.$store.getters['GET_ISSUED_TICKETS'];
    }

    get selectedTickets(): IDynamicTicket[] {
        return this.tickets.filter(ticket => ticket.selected);
    }

    get dynamicHeaders(): unknown[] {
        const defaultHeaders: string[] = [
            'action',
            'code',
            'owner',
            'title',
            'desc'
        ];
        const headers: string[] = [];

        for (const ticket of this.tickets) {
            const props = Object.keys(ticket as never);

            for (const prop of props) {
                if (!headers.includes(prop) && !defaultHeaders.includes(prop)) headers.push(prop);
            }
        }

        headers.splice(headers.indexOf('issuer'), 1);
        headers.splice(headers.indexOf('selected'), 1);
        !this.showValue && headers.splice(headers.indexOf('value'), 1);

        headers.sort();

        return [...defaultHeaders, ...headers].map(h => {
            const result = {
                text: `${h[0].toUpperCase()}${h.substr(1)}`,
                value: h,
                align: 'start',
                sortable: true
            }

            if (h === 'action') {
                result.sortable = false;
                result.text = '';
            }

            return result;
        });
    }

    mounted(): void {
        if (this.loginState === LoginState.LoggedIn) {
            this.reload();
        }
    }

    reload(): void {
        this.$store.dispatch('FETCH_ISSUED_TICKETS');
    }

    openDeleteConfirmation(): void {
        this.confirmationDialog = true;
    }

    async deleteSelection(): Promise<void> {
        this.confirmationDialog = false;

        for (const ticket of this.selectedTickets) {
            await this.$store.dispatch('DELETE_TICKET', ticket.code);
        }

        this.reload();
    }

    @Watch('loginState')
    onLoginChanged(): void {
        if (this.loginState === LoginState.LoggedIn) {
            this.reload();
        }
    }
}
