import axios, {AxiosInstance, AxiosResponse} from "axios";
import {User} from "firebase/auth";
import store from '@/store'
import ITicket from "@/typescript/interfaces/ITicket";

const getAxios = async (): Promise<AxiosInstance> => {
    const token = await (store.getters['GET_FIREBASE_USER'] as User).getIdToken(true);

    return axios.create({
        headers: {
            Authorization: token
        }
    })
}

export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://api.ticketclub.app' : `http://${window.location.hostname}:3002`;

const api = Object.freeze({
    UPLOAD_FILE: async <T>(content: string): Promise<AxiosResponse<T>> => (await getAxios()).post(`${baseUrl}/v1/file`, content, {
        headers: {
            'Content-Type': 'text/plain'
        }
    }),
    POST_TICKET: async <T>(content: ITicket): Promise<AxiosResponse<T>> => (await getAxios()).post(`${baseUrl}/v1/ticket`, content, {
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    GET_ADMIN: async <T>(): Promise<AxiosResponse<T>> => (await getAxios()).get(`${baseUrl}/v1/admin`),
    GET_ISSUED_TICKETS: async <T>(): Promise<AxiosResponse<T>> => (await getAxios()).get(`${baseUrl}/v1/admin/issued`),
    DELETE_TICKET: async <T>(code: string): Promise<AxiosResponse<T>> => (await getAxios()).delete(`${baseUrl}/v1/admin/delete-ticket/${code}`),
});

export default api;