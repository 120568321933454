












































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import LoginDialog from "@/components/loginDialog.vue";
import UploadDialog from "@/components/uploadDialog.vue";
import LoginState from "@/typescript/enums/LoginState";
import Package from "@/../package.json";
import UpdateDialog from "@/components/updateDialog.vue";
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import {User} from "firebase/auth";

@Component({
    components: {UpdateDialog, UploadDialog, LoginDialog},
})
export default class extends Vue {
    routes: unknown = [
        {
            icon: 'mdi-home',
            label: 'Início',
            route: '/'
        },
        {
            icon: 'mdi-card-plus',
            label: 'Emitir',
            route: '/create'
        },
        {
            icon: 'mdi-table',
            label: 'Emissões',
            route: '/manage'
        },
        {
            icon: 'mdi-logout',
            label: 'Sair',
            route: '/logout'
        }
    ]

    get LoginState(): typeof LoginState {
        return LoginState;
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    get user(): User {
        return this.$store.getters['GET_FIREBASE_USER'];
    }

    get version(): string {
        return `v${Package.version}`
    }

    set showNavigation(newValue: boolean) {
        this.$store.commit('SET_SHOW_NAVIGATION_MENU', newValue);
    }

    get showNavigation(): boolean {
        return this.$store.getters['GET_SHOW_NAVIGATION_MENU']
    }

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    logout(): void {
        this.$store.dispatch('LOGOUT');
    }

    created(): void {
        window.addEventListener("outdated", () => {
            this.dialogManager.dispatch(Dialogs.UpdateDialog);
        })
    }

    @Watch('loginState')
    @Watch('$route.path')
    onLoginOrRouteChanged(): void {
        if (this.loginState !== LoginState.LoggedIn && this.$route.path !== '/') {
            this.$router.push('/').catch(console.error);
        }
    }
}
