

























































































import {Component, Vue} from 'vue-property-decorator';
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";
import ITicket from '@/typescript/interfaces/ITicket';
import api, {baseUrl} from "@/plugins/api";
import IUploadFile from '@/typescript/interfaces/IUploadFile';
import RequestStatus from "@/typescript/interfaces/RequestStatus";
import isNil from "lodash/isNil";

@Component({
    components: {},
})
export default class extends Vue {
    private url: string = "";
    private show: boolean = false;
    private status: RequestStatus = RequestStatus.InProgress;

    private get RequestStatus(): typeof RequestStatus {
        return RequestStatus;
    }

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    private get StatusText(): string {
        switch (this.status) {
            case RequestStatus.InProgress:
                return 'Enviando...';
            case RequestStatus.Done:
                return 'Enviado!';
            case RequestStatus.Error:
                return 'Falha';
        }

        return '';
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.UploadDialog, this.doUpload);
    }

    async doUpload(ticket: ITicket, base64Icon: string | undefined): Promise<void> {
        this.show = true;
        this.status = RequestStatus.InProgress;

        try {
            if (!isNil(base64Icon)) {
                const {data: {file}} = await api.UPLOAD_FILE<IUploadFile>(base64Icon as unknown as string);
                ticket.icon = `${baseUrl}/v1/file/${file}`;
            }

            await api.POST_TICKET<ITicket>(ticket);
            this.url = `https://ticketclub.app/#/add/${ticket.code}`;
            this.status = RequestStatus.Done;
            this.$router.push('/').catch(console.error);
        } catch (e) {
            this.status = RequestStatus.Error;
            console.error(e);
        }
    }
}
