










































import {Component, Vue} from 'vue-property-decorator';
import {Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import LoginState from "@/typescript/enums/LoginState";
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";

@Component({
    components: {},
})
export default class extends Vue {
    private isLoggingIn: boolean = false;
    private show: boolean = false;
    private label: string = '';

    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get auth(): Auth {
        return this.$store.getters['GET_FIREBASE_AUTH'];
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    mounted(): void {
        this.dialogManager.on(Dialogs.LoginDialog, (text: string | undefined) => {
            this.label = text || '';
            this.show = true;
        });
    }

    async login(): Promise<void> {
      if(this.isLoggingIn) return;

      try {
        this.isLoggingIn = true;

        if (this.loginState !== LoginState.LoggedOut) {
          this.show = false;
          return;
        }

        const provider = new GoogleAuthProvider();
        await signInWithPopup(this.auth, provider);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoggingIn = false;
        this.show = false;
      }
    }
}
