import Vue from 'vue'
import Vuex from 'vuex'
import Dialogs from "@/typescript/enums/Dialogs";
import EventManager from "@/plugins/EventManager";
import {FirebaseApp} from "firebase/app";
import {Auth, User} from "firebase/auth";
import LoginState from "@/typescript/enums/LoginState";
import IAdmin from "@/typescript/interfaces/IAdmin";
import isNil from "lodash/isNil";
import Api from "@/plugins/api";
import IDynamicTicket from "@/typescript/interfaces/IDynamicTicket";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dialogManager: undefined,
        waitingFirebase: true,
        firebaseApp: undefined,
        firebaseAuth: undefined,
        firebaseUser: undefined,
        firebaseAnalytics: undefined,
        firebasePerformance: undefined,
        showNavigationMenu: undefined,
        issuedTickets: [],
        isFetchingIssuedTickets: false,
        isDeletingTicket: false
    },
    mutations: {
        SET_FIREBASE_APP(state, payload) {
            state.firebaseApp = payload;
        },
        SET_FIREBASE_AUTH(state, payload) {
            state.firebaseAuth = payload;
        },
        SET_FIREBASE_USER(state, payload) {
            state.firebaseUser = payload;
        },
        SET_WAITING_FIREBASE(state, payload) {
            state.waitingFirebase = payload;
        },
        SET_FIREBASE_ANALYTICS(state, payload) {
            state.firebaseAnalytics = payload;
        },
        SET_FIREBASE_PERFORMANCE(state, payload) {
            state.firebasePerformance = payload;
        },
        SET_DIALOG_MANAGER(state, payload) {
            state.dialogManager = payload;
        },
        SET_SHOW_NAVIGATION_MENU(state, payload) {
            state.showNavigationMenu = payload;
        },
        SET_ISSUED_TICKETS(state, payload) {
            state.issuedTickets = payload;
        },
        SET_FETCHING_ISSUED_TICKETS(state, payload) {
            state.isFetchingIssuedTickets = payload;
        },
        SET_DELETING_TICKET(state, payload) {
            state.isDeletingTicket = payload;
        }
    },
    actions: {
        UPDATE_FIREBASE_APP(store, payload) {
            store.commit('SET_FIREBASE_APP', payload);
        },
        UPDATE_FIREBASE_AUTH(store, auth: Auth) {
            auth.useDeviceLanguage();
            auth.onAuthStateChanged(async userData => {
                store.dispatch('UPDATE_FIREBASE_USER', userData);
                store.dispatch('UPDATE_WAITING_FIREBASE', false);

                if (!isNil(userData)) {
                    store.dispatch('CHECK_USER_STATE');
                }
            });

            store.commit('SET_FIREBASE_AUTH', auth);
        },
        UPDATE_FIREBASE_USER(store, payload) {
            store.commit('SET_FIREBASE_USER', payload);
        },
        UPDATE_WAITING_FIREBASE(store, payload) {
            store.commit('SET_WAITING_FIREBASE', payload);
        },
        UPDATE_FIREBASE_ANALYTICS(store, payload) {
            store.commit('SET_FIREBASE_ANALYTICS', payload);
        },
        UPDATE_FIREBASE_PERFORMANCE(store, payload) {
            store.commit('SET_FIREBASE_PERFORMANCE', payload);
        },
        async LOGOUT(store) {
            await (store.state.firebaseAuth as unknown as Auth).signOut()
            store.dispatch('UPDATE_FIREBASE_USER', null);
        },
        SETUP_DIALOG_MANAGER(store) {
            store.commit('SET_DIALOG_MANAGER', new EventManager(Object.values(Dialogs)));
        },
        async CHECK_USER_STATE(store) {
            try {
                const {data: admin} = await Api.GET_ADMIN<IAdmin>();

                if (!admin.enabled) {
                    store.dispatch('LOGOUT');
                }
            } catch {
                store.dispatch('LOGOUT');
            }
        },
        async FETCH_ISSUED_TICKETS(store) {
            store.commit('SET_FETCHING_ISSUED_TICKETS', true);
            try {
                const {data: tickets} = await Api.GET_ISSUED_TICKETS<IDynamicTicket[]>();
                store.commit('SET_ISSUED_TICKETS', tickets.map(ticket => {
                    ticket.selected = false;
                    return ticket;
                }));
            } catch (e) {
                console.error(e);
            } finally {
                store.commit('SET_FETCHING_ISSUED_TICKETS', false);
            }
        },
        async DELETE_TICKET(store, code: string) {
            store.commit('SET_DELETING_TICKET', true);
            try {
                await Api.DELETE_TICKET<unknown>(code);
            } catch (e) {
                console.error(e);
            } finally {
                store.commit('SET_DELETING_TICKET', false);
            }
        }
    },
    getters: {
        GET_LOGIN_STATE(state): LoginState {
            if (isNil(state.firebaseUser) && state.waitingFirebase) {
                return LoginState.Pending;
            } else if (isNil(state.firebaseUser)) {
                return LoginState.LoggedOut;
            } else if (!isNil(state.firebaseUser)) {
                return LoginState.LoggedIn;
            }

            return LoginState.Unknown;
        },
        GET_FIREBASE_APP(state): FirebaseApp {
            return state.firebaseApp as unknown as FirebaseApp;
        },
        GET_FIREBASE_AUTH(state): Auth {
            return state.firebaseAuth as unknown as Auth;
        },
        GET_FIREBASE_USER(state): User {
            return state.firebaseUser as unknown as User;
        },
        GET_DIALOG_MANAGER(state): EventManager {
            return state.dialogManager as unknown as EventManager;
        },
        GET_SHOW_NAVIGATION_MENU(state): boolean {
            return state.showNavigationMenu as unknown as boolean;
        },
        GET_ISSUED_TICKETS(state): IDynamicTicket {
            return state.issuedTickets as unknown as IDynamicTicket;
        },
        GET_FETCHING_ISSUED_TICKETS(state): boolean {
            return state.isFetchingIssuedTickets as unknown as boolean;
        },
        GET_DELETING_TICKET(state): boolean {
            return state.isDeletingTicket as unknown as boolean;
        }
    },
    modules: {}
})
