










import {Component, Vue} from 'vue-property-decorator';

@Component
export default class extends Vue {
    mounted(): void {
        this.$store.dispatch('LOGOUT');
    }
}
