

























































import {Component, Vue} from 'vue-property-decorator';
import EventManager from "@/plugins/EventManager";
import Dialogs from "@/typescript/enums/Dialogs";
import LoginState from "@/typescript/enums/LoginState";

@Component({
    components: {},
})
export default class extends Vue {
    private get dialogManager(): EventManager {
        return this.$store.getters['GET_DIALOG_MANAGER'];
    }

    get LoginState(): typeof LoginState {
        return LoginState
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    openNavigation(): void {
        this.$store.commit('SET_SHOW_NAVIGATION_MENU', true);
    }

    openLogin(): void {
        this.dialogManager.dispatch(Dialogs.LoginDialog);
    }
}
