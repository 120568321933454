








































































import {Component, Vue, Watch} from 'vue-property-decorator';
import UploadDialog from "@/components/uploadDialog.vue";
import UpdateDialog from "@/components/updateDialog.vue";
import LoginDialog from "@/components/loginDialog.vue";
import LoginState from '@/typescript/enums/LoginState';

@Component({
    components: {UpdateDialog, UploadDialog, LoginDialog},
})
export default class extends Vue {
    headers: unknown[] = [
        {
            text: "Data",
            value: "date",
            align: 'start',
            sortable: true
        },
        {
            text: "Valor",
            value: "value",
            align: 'start',
            sortable: true
        },
        {
            text: "Emissões",
            value: "emissions",
            align: 'start',
            sortable: true
        },
        {
            text: "Ativos",
            value: "active",
            align: 'start',
            sortable: true
        },
        {
            text: "Pago",
            value: "paid",
            align: 'start',
            sortable: false
        }
    ]
    data: unknown[] = [
        {
            value: 'R$ 0,00',
            date: '22/02/2022',
            emissions: 0,
            active: 0,
            paid: false
        }
    ]

    get progress(): number {
        return 0;
    }

    get loginState(): LoginState {
        return this.$store.getters['GET_LOGIN_STATE'];
    }

    mounted(): void {
        if (this.loginState === LoginState.LoggedIn) {
            this.reload();
        }
    }

    reload(): void {
        this.$store.dispatch('FETCH_ISSUED_TICKETS');
    }

    @Watch('loginState')
    onLoginChanged(): void {
        if (this.loginState === LoginState.LoggedIn) {
            this.reload();
        }
    }
}
