import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import Create from '../views/Create.vue'
import Manage from '../views/Manage.vue'
import Billing from '../views/Billing.vue'
import Logout from '../views/Logout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/create',
        name: 'Create',
        component: Create
    },
    {
        path: '/manage',
        name: 'Manage',
        component: Manage
    },
    {
        path: '/billing',
        name: 'Billing',
        component: Billing
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    }
]

const router = new VueRouter({
    routes
})

export default router
